import * as React from 'react';
import { graphql } from 'gatsby';
import { css } from '@emotion/core';
import Layout from '../layout';
import padding from '../../util/padding';
import PostBlock from '../post-block';

export type Props = {
  pageContext: {
    tag: string;
  };
  data: {
    allMdx: {
      edges: Array<{
        node: {
          id: string;
          timeToRead: string;
          frontmatter: {
            slug: string;
            title: string;
            date: string;
            tags: Array<string>;
            hero: {
              darken?: string;
              author?: string;
              unsplashUser?: string;
              file: {
                childImageSharp: {
                  fluid: {
                    src: string;
                    srcSet: string;
                  };
                };
              };
            };
          };
          body: string;
          excerpt: string;
        };
      }>;
    };
  };
};

const TagPage = ({ pageContext, data }: Props) => {
  const posts = data.allMdx.edges;

  return (
    <Layout>
      <h3 css={padding}>#{pageContext.tag}</h3>
      <div
        css={css`
          ${padding}
          display: flex;
          align-items: center;
          flex-direction: column;
        `}
      >
        {posts.map(({ node: post }) => (
          <PostBlock
            key={post.id}
            slug={post.frontmatter.slug}
            title={post.frontmatter.title}
            date={post.frontmatter.date}
            tags={post.frontmatter.tags}
            excerpt={post.excerpt}
            image={post.frontmatter.hero.file.childImageSharp.fluid}
          />
        ))}
      </div>
    </Layout>
  );
};

export default TagPage;

export const tagQuery = graphql`
  query($tag: String!) {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      edges {
        node {
          id
          excerpt
          timeToRead
          frontmatter {
            slug
            title
            date(formatString: "MMMM DD, YYYY")
            tags
            hero {
              file {
                childImageSharp {
                  fluid(
                    maxWidth: 720
                    maxHeight: 400
                    fit: COVER
                    jpegProgressive: true
                  ) {
                    src
                    srcSet
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
